@tailwind base;
@tailwind components;
@tailwind utilities;

.bbb {
  color: white;
}

.custom-card-image .card.image-full:before {
  border-radius: 0.5rem;
  opacity: 0.45;
}
